import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Link } from "gatsby-theme-material-ui";
import { graphql } from "gatsby";
import ContentfulRichText, { ContentfulRichTextType } from "src/contentful/contentful-rich-text";
import { Paths } from "src/utilities/constants";
import CustomLayout, { IHeaderProps, headerTypes } from "src/components/layout/custom-layout";
import { navigate } from "@reach/router";
import { AboutPageQueryQuery } from "types/graphql-types";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}))

interface IProps {
  data: AboutPageQueryQuery
}
const Index = ({ data }: IProps) => {

  const aboutPageData = data.allContentfulAboutPage.edges[0].node

  const headerProps: IHeaderProps = {
    type: headerTypes.logo
  }

  return (
    <CustomLayout
      footerProps={{}}
      headerProps={headerProps}
    >
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Link onClick={() => { navigate(-1) }}>{aboutPageData.backButtonLabel}</Link>
        </Grid>
        <Grid item xs={12}>
          <ContentfulRichText {...aboutPageData?.content as ContentfulRichTextType} />
        </Grid>
        <Grid item xs={12} >
          <Link onClick={() => { navigate(-1) }}>{aboutPageData.backButtonLabel}</Link>
        </Grid>
      </Grid>
    </CustomLayout>
  );
}

export const query = graphql`
  query AboutPageQuery {
    allContentfulAboutPage {
      edges {
        node {
          id
          content {
            raw
          }
          backButtonLabel
        }
      }
    }
  }
`

export default Index;